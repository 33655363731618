import React from 'react';
import styled from 'styled-components';
import { SectionHeading } from '../../../components/sharedComponents';
import ScrollAnimation from 'react-animate-on-scroll';

// images
import careerTransitionImage from '../../../assets/images/careerTransition-service.svg';
import careerManagementImage from '../../../assets/images/careerManagement-service.svg';
import retireImage from '../../../assets/images/retire-service.svg';

const howItWorksCards = [
	{
		title: 'Career Transition Services',
		subtitle:
			'Are you between jobs? I’ll coach you on the art of creating an effective resume that sets you apart from the competition. Together we’ll create a LinkedIn profile that showcases your professional skills and accomplishments.  From charting your path to perfecting your networking and interviewing skills, I’ll guide you towards landing not just a job but one that will start you on your journey to achieving your career goals.'
	},
	{
		title: 'Career Management Services',
		subtitle:
			'Are you currently working but confused about how to go about planning your professional future? Are you struggling to refine and improve your skillset to align with your goals? Let’s define your career path and determine the necessary steps you need to take for successful advancement in your field.'
	},
	{
		title: 'Retirement',
		subtitle:
			'What does retirement mean to you? Are you ready to leave your profession and move on to the next stage of your life? Retirement doesn’t always mean giving up meaningful and productive pursuits. This can be a time to explore opportunities you’ve always dreamed of –– writing your memoir, counselling others, perhaps inventing the newest mousetrap! Let’s explore ways to make this important period exciting and fulfilling.'
	}
	// {
	// 	title: 'Get Featured and Get Hired',
	// 	subtitle:
	// 		'The moment you are featured, you will be flooded with offers. On average, a single developer receives 5+ offers within 7 days.'
	// }
];

const WorkCard = styled.div`
	border-radius: 0.5rem;
	text-align: center;
	padding: 2rem;
	direction: ltr;
`;

const WorkCardTitle = styled.h3`font-weight: bold;`;

const WorkCardDesc = styled.div`direction: ltr;`;

const WordCardGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 1rem;
	align-items: center;
	direction: ${({ reverse }) => (reverse ? 'rtl' : 'ltr')};
	margin: 4rem 0;

	@media (max-width: 550px) {
		grid-template-columns: 1fr;
		direction: ltr;
	}
`;

const WorkImage = styled.img`
	max-height: 300px;
	object-fit: contain;
	object-position: center;
`;

const HowItWorksSection = (props) => {
	return (
		<section className="how-it-works">
			<div className="container">
				{/* <ScrollAnimation animateIn="fadeIn"> */}
				<SectionHeading className={'active'}>
					Services Offered
				</SectionHeading>
				{/* {</ScrollAnimation> */}
				{/* <ScrollAnimation animateIn="fadeIn"> */}
				<WordCardGrid>
					{/* <WordCardPosition x={1} y={1}> */}
					<WorkImage src={careerTransitionImage} alt="" />
					<WorkCard>
						<WorkCardTitle>
							{howItWorksCards[0].title}
						</WorkCardTitle>
						<WorkCardDesc>
							{howItWorksCards[0].subtitle}
						</WorkCardDesc>
					</WorkCard>
				</WordCardGrid>
				{/* {</ScrollAnimation> */}
				{/* <ScrollAnimation animateIn="fadeIn"> */}
				<WordCardGrid reverse>
					<WorkImage src={careerManagementImage} alt="" />
					<WorkCard>
						<WorkCardTitle>
							{howItWorksCards[1].title}
						</WorkCardTitle>
						<WorkCardDesc>
							{howItWorksCards[1].subtitle}
						</WorkCardDesc>
					</WorkCard>
				</WordCardGrid>
				{/* </ScrollAnimation> */}
				{/* <ScrollAnimation animateIn="fadeIn"> */}
				<WordCardGrid>
					<WorkImage src={retireImage} alt="" />
					<WorkCard>
						<WorkCardTitle>
							{howItWorksCards[2].title}
						</WorkCardTitle>
						<WorkCardDesc>
							{howItWorksCards[2].subtitle}
						</WorkCardDesc>
					</WorkCard>
				</WordCardGrid>
				{/* </ScrollAnimation> */}
				{/* <ScrollAnimation animateIn="fadeIn">
					<WordCardGrid reverse>
						<WorkImage src={hireImage} alt="" />
						<WorkCard>
							<WorkCardTitle>
								{howItWorksCards[3].title}
							</WorkCardTitle>
							<WorkCardDesc>
								{howItWorksCards[3].subtitle}
							</WorkCardDesc>
						</WorkCard>
					</WordCardGrid>
				</ScrollAnimation> */}
			</div>
		</section>
	);
};

export default HowItWorksSection;
