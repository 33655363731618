import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import ScrollAnimation from "react-animate-on-scroll"

import { Button } from "../../../components/sharedComponents"

// images
import splashBackgroundImage from "../../../assets/images/splash-screen-image.svg"
import { showRegistrationForm } from "../../../components/helpers"

const SplashBackground = styled.div`
    /* background: url(${splashBackgroundImage}) no-repeat ; */
    /* background-color: #00000073; */
    background-size: cover;
    background-attachment: fixed;
    background-blend-mode: overlay;
	overflow: hidden;

	
`

const SplashContent = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  justify-content: center;
  /* grid-gap: 1rem;
    grid-template: 1fr 1fr; */
  /* color: white; */
  min-height: calc(100vh - 12.5rem);

  h1 {
    font-size: 1.8rem;
    padding-top: 2rem;
  }

  @media (max-width: 360px) {
    min-height: 100vh;
  }
`

const ButtonWrap = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 1rem;

  @media (max-width: 550px) {
    grid-template-columns: 1fr;
  }

  @media only screen and (device-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const CounterGrid = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: min-content min-content min-content;
  padding: 1.5rem 0;
  justify-content: space-between;

  @media (max-width: 550px) {
    grid-template-columns: 1fr;
  }
`

const CounterCell = styled.div`
  text-align: center;

  small {
    margin-left: -0.5rem;
  }
`

const CounterText = styled.h5`
  width: max-content;
  line-height: 1.2rem;

  @media (max-width: 550px) {
    width: 100%;
  }
`

const CounterNumber = styled.div`
  color: ${({ theme }) => theme.accentPrimary};
  font-size: 3rem;
  font-weight: bold;
`

const SplashGrid = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr;
  align-items: center;

  @media (max-width: 550px) {
    grid-template-columns: 1fr;

    img {
      display: none;
    }
  }
`

const CounterBar = styled.div`
  background: #c77dff3e;
`

const SplashCounter = () => (
  <CounterBar>
    <div className="container">
      <CounterGrid>
        {/* <ScrollAnimation
					animateIn="fadeInDown"
					delay={500}
					offset={800}
				> */}
        <CounterCell>
          <CounterNumber>15+</CounterNumber>
          <CounterText>
            --INDUSTRIES--
            <br />
            Coaching clients from
            <br /> multiple sectors
          </CounterText>
        </CounterCell>
        {/* </ScrollAnimation>
				<ScrollAnimation
					animateIn="fadeInDown"
					delay={600}
					offset={800}
				> */}
        <CounterCell>
          <CounterNumber>
            10+
            {/* # <small>#</small> */}
          </CounterNumber>
          <CounterText>
            --YEARS-- <br />
            Coaching clients regarding <br />
            their careers
          </CounterText>
        </CounterCell>
        {/* </ScrollAnimation>

				<ScrollAnimation
					animateIn="fadeInDown"
					delay={700}
					offset={800}
				> */}
        <CounterCell>
          <CounterNumber>
            400+
            {/* # <small>#</small> */}
          </CounterNumber>
          <CounterText>
            --CLIENTS--
            <br />
            Ranging from students to
            <br /> C-suite executives
          </CounterText>
        </CounterCell>
        {/* </ScrollAnimation> */}
      </CounterGrid>
    </div>
  </CounterBar>
)

const SplashScreenSection = props => {
  return (
    <SplashBackground>
      <div className="container">
        <SplashGrid>
          <SplashContent>
            {/* <ScrollAnimation animateIn="fadeIn"> */}
            <h1>
              I coach individuals to take charge of their career, redefine and
              believe in themselves, and achieve their professional goals
            </h1>
            {/* </ScrollAnimation> */}
            <ul>
              {/* <ScrollAnimation animateIn="fadeIn" delay={300}> */}
              <li>
                If you’ve just graduated from school and are looking for your
                first job, currently between jobs, or you’re stuck in a job
                that’s not fulfilling, I’ll help you navigate your way.
              </li>
              {/* </ScrollAnimation> */}
              {/* <ScrollAnimation animateIn="fadeIn" delay={600}> */}
              <li>
                If you love what you do but are not sure about your next steps
                moving forward or you’re considering retirement but don’t want
                to just sit on the couch, let’s discuss your options and chart
                your path.
              </li>
              {/* </ScrollAnimation> */}
            </ul>
            {/* <ScrollAnimation animateIn="fadeIn" delay={1000}> */}
            <ButtonWrap>
              <Button fit primary onClick={showRegistrationForm}>
                Get In Touch!
              </Button>
              <Button
                primary
                onClick={() =>
                  document.querySelector(".how-it-works").scrollIntoView({
                    behavior: "smooth",
                  })
                }
              >
                Services Offered
              </Button>
              <Button
                primary
                onClick={() =>
                  document.querySelector(".testimonials").scrollIntoView({
                    behavior: "smooth",
                  })
                }
              >
                Testimonials
              </Button>
            </ButtonWrap>
            {/* </ScrollAnimation> */}
          </SplashContent>

          {/* <ScrollAnimation animateIn="fadeIn" delay={400}> */}
          <img
            style={{ paddingLeft: "2rem" }}
            src={splashBackgroundImage}
            alt=""
          />
          {/* </ScrollAnimation> */}
        </SplashGrid>
      </div>
      {/* <ScrollAnimation animateIn="fadeInUp" delay={400} offset={800}> */}
      <SplashCounter />
      {/* </ScrollAnimation> */}
    </SplashBackground>
  )
}

export default SplashScreenSection
