import React, { useState } from 'react';
import styled from 'styled-components';

import { SectionHeading } from '../../../components/sharedComponents';

// images
import jaiProfile from '../../../assets/images/profile.png';
import sayaniProfile from '../../../assets/images/profile.png';
import adityaProfile from '../../../assets/images/profile.png';
import devProfile from '../../../assets/images/profile.png';
import vasanthProfile from '../../../assets/images/profile.png';

const TestimonialGird = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 2rem;
	justify-content: space-between;
	text-align: center;
	padding: 2rem 0;

	@media (max-width: 550px) {
		grid-template-columns: 1fr;
	}
`;

const TestimonialCard = styled.div`
  min-height: 300px;
  padding: 2rem 1rem;
  position: relative;
  background: white;
  margin-top: 6rem;
  box-shadow: 0 0 10px -5px;
  width: 90%;
  margin: 3rem auto 0;

  display: flex;
  flex-direction: column;

  &:before {
    content: "";
    position: absolute;
    top: -1rem;
    left: -1rem;
    height: 100%;
    width: 100%;
   // background: ${({ theme }) => theme.accentLight};
    z-index: -1;
  }

  @media (max-width: 550px) {
    min-height: 400px;
  }
`;

const developerTestimonial = [
	{
		cite:
			"I love Stephanie's radical candor as her genuine desire to work with you and ensure your success is unmistakable. I recommend you take full advantage of her extensive knowledge and attentive council. You won't be disappointed.",
		author: 'Angela',
		image: adityaProfile
	},
	{
		cite:
			'Professional, tech savvy, career coach. Stephanie helped me build my confidence during my period of transition and challenged me to ask myself honest questions about my career. She provided me excellent tools for my search and gave me effective networking tips.',
		author: 'Amy L.',
		image: jaiProfile
	}
];

const startupTestimonials = [
	{
		cite:
			'Stephanie is very professional and understands her clients. She was instrumental in helping me put together a polished resume and LinkedIn profile. Her knowledge and EQ allow her to assist clients in the best way possible',
		author: 'KB',
		image: devProfile
	},
	{
		cite:
			"Working with Stephanie not only righted the ride, but grew me both professionally and personally. She's a master at extracting unique strengths and uncovering opportunities that you may have not considered",
		author: 'A. C.',
		image: vasanthProfile
	}
];

const Indicator = styled.div`
	height: 4px;
	width: ${(props) => (props.active ? '2rem' : '1rem')};
	margin: 0.5rem;
	background: ${(props) => (props.active ? '#000' : '#707070')};
	transition: all 0.3s ease-in-out;
	border-radius: 0;
	cursor: pointer;
`;

const IndicatorWrap = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.5rem 0;

	&:hover ${Indicator} {
		height: 10px;
	}
`;

const Testimonials = (props) => {
	const [ activeIndex, setIndex ] = useState(0);
	const data = props.data[activeIndex];
	return (
		<TestimonialCard>
			<img
				style={{
					borderRadius: '50%',
					height: '80px',
					width: '80px',
					objectFit: 'contain',
					objectPosition: 'center',
					margin: '0rem auto 2rem'
				}}
				src={
					data.image ||
					'https://media.licdn.com/dms/image/C4D03AQGqDxAwdRLP9Q/profile-displayphoto-shrink_800_800/0?e=1564617600&v=beta&t=v7gi8AI-SnpLNkTasWTy3HvcWWkalfVicrYwkKCxeL8'
				}
				alt=""
			/>
			<h4>{data.author}</h4>
			<p style={{ flex: 1 }}>{data.cite}</p>

			<IndicatorWrap>
				{props.data.map((a, idx) => (
					<Indicator
						key={idx}
						active={idx === activeIndex}
						onClick={() => setIndex(idx)}
					/>
				))}
			</IndicatorWrap>
		</TestimonialCard>
	);
};

const TestimonialSection = () => {
	return (
		<section className="testimonials" style={{ paddingTop: 0 }}>
			<div className="container">
				<SectionHeading>Testimonials</SectionHeading>
				<TestimonialGird>
					<div>
						{/* <h3>Testimonial Set 1</h3> */}

						<Testimonials data={developerTestimonial} />
					</div>

					<div>
						{/* <h3>Testimonial Set 2</h3> */}

						<Testimonials data={startupTestimonials} />
					</div>
				</TestimonialGird>
			</div>
		</section>
	);
};

export default TestimonialSection;
