import React from "react"
import styled from "styled-components"
import ScrollAnimation from "react-animate-on-scroll"

import { SectionHeading } from "../../../components/sharedComponents"

const WhyGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  > div:first-child > div {
    border-right: 1px solid #c3c3c3;
  }

  @media (max-width: 550px) {
    grid-template-columns: 1fr;

    > div:first-child > div {
      border-right: none;
    }
  }
`

const WhyCard = styled.div`
  padding: 2rem 4rem;
  text-align: center;
  margin: 2rem 0;

  @media (max-width: 550px) {
    margin: 0;
    padding: 2rem 0;
  }
`

const WhySkipTheLineSection = props => (
  <section>
    <div className="container">
      <ScrollAnimation animateIn="fadeIn">
        <SectionHeading>Why SkipTheLine?</SectionHeading>
      </ScrollAnimation>
      <WhyGrid>
        <ScrollAnimation animateIn="fadeIn">
          <WhyCard>
            <h3>Good developers at wrong workplaces</h3>
            <span>
              Too many developers don’t realize their true potential and are
              stuck at the wrong workplace
            </span>
          </WhyCard>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeIn">
          <WhyCard>
            <h3>Access to opportunities</h3>
            <span>
              Every good developer should have access to the best opportunities
              and not left to fend for themselves. You have worked hard to
              acquire the skills, let the jobs come to you{" "}
            </span>
          </WhyCard>
        </ScrollAnimation>
      </WhyGrid>
    </div>
  </section>
)

export default WhySkipTheLineSection
